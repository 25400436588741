import React from 'react';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Nav = () => {
    const { t } = useTranslation();
    return (
        <ul className="mainmenu">
            <li className="has-droupdown"><Link to="/">{t('Home')}</Link></li>
            <li className="has-droupdown"><Link to="/about">{t('AboutUs')}</Link></li>
            <li className="has-droupdown"><Link to="/contact">{t('Contact')}</Link></li>
            <li className="has-droupdown"><Link to="/case-studies">{t('caseStudy')}</Link></li>
        </ul>
    )
}
export default Nav;
