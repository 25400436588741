import React from "react";
import SEO from "../common/SEO";
import HeaderOne from '../common/header/HeaderOne';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import { useTranslation } from 'react-i18next';
import Chat from '../common/chat_query';
import CaseStudie from '../components/case/CaseStudies';
import CalltoActionTwo from "../elements/calltoaction/CalltoActionTwo";

const CaseStudies = () => {
    const { t } = useTranslation();

    return (
        <>
            <Chat/>
            <SEO title={t('caseStudy')} />
            <main className="page-wrapper">
                <HeaderOne btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
                <CaseStudie />
                <CalltoActionTwo />
                <br />
                <br />
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default CaseStudies;
