import React from 'react';
import Typed from 'react-typed';
import { useTranslation } from "react-i18next";

const PrivacyPage = () => {
    const { t } = useTranslation();
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-12 mt_md--40 mt_sm--40 p-50">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title" style={{ textAlign: "center" }}>
                                    <Typed className="theme-gradient"
                                        strings={[t('kvkk.mainTitle')]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>

                                {/* Section 1 */}
                                <h3>{t('kvkk.section1.title')}</h3>
                                <h4>{t('kvkk.section1.intro')}</h4>
                                <p>{t('kvkk.section1.introText')}</p>

                                <h4>{t('kvkk.section1.scope')}</h4>
                                <p>{t('kvkk.section1.scopeText')}</p>
                                <p>{t('kvkk.section1.scopeDetails')}</p>

                                <h4>{t('kvkk.section1.implementation')}</h4>
                                <p>{t('kvkk.section1.implementationText')}</p>

                                <h4>{t('kvkk.section1.enforcement')}</h4>
                                <p>{t('kvkk.section1.enforcementText')}</p>

                                {/* Section 2 */}
                                <h3>{t('kvkk.section2.title')}</h3>
                                <h4>{t('kvkk.section2.security')}</h4>
                                <p>{t('kvkk.section2.securityText')}</p>

                                <h4>{t('kvkk.section2.privateData')}</h4>
                                <p>{t('kvkk.section2.privateDataText')}</p>
                                <p>{t('kvkk.section2.privateDataDetails')}</p>

                                <h4>{t('kvkk.section2.awareness')}</h4>
                                <p>{t('kvkk.section2.awarenessText')}</p>

                                {/* Section 3 */}
                                <h3>{t('kvkk.section3.title')}</h3>
                                <h4>{t('kvkk.section3.processing.title')}</h4>

                                <h5>{t('kvkk.section3.processing.lawAndFaith')}</h5>
                                <p>{t('kvkk.section3.processing.lawAndFaithText')}</p>

                                <h5>{t('kvkk.section3.processing.accuracy')}</h5>
                                <p>{t('kvkk.section3.processing.accuracyText')}</p>

                                <h5>{t('kvkk.section3.processing.purpose')}</h5>
                                <p>{t('kvkk.section3.processing.purposeText')}</p>

                                <h5>{t('kvkk.section3.processing.limitation')}</h5>
                                <p>{t('kvkk.section3.processing.limitationText')}</p>

                                <h5>{t('kvkk.section3.processing.storage')}</h5>
                                <p>{t('kvkk.section3.processing.storageText')}</p>

                                {/* Requirements */}
                                <h4>{t('kvkk.section3.requirements.title')}</h4>
                                <p>{t('kvkk.section3.requirements.intro')}</p>

                                <h5>{t('kvkk.section3.requirements.consent.title')}</h5>
                                <p>{t('kvkk.section3.requirements.consent.text')}</p>

                                <h5>{t('kvkk.section3.requirements.law.title')}</h5>
                                <p>{t('kvkk.section3.requirements.law.text')}</p>

                                <h5>{t('kvkk.section3.requirements.impossibility.title')}</h5>
                                <p>{t('kvkk.section3.requirements.impossibility.text')}</p>

                                <h5>{t('kvkk.section3.requirements.contract.title')}</h5>
                                <p>{t('kvkk.section3.requirements.contract.text')}</p>

                                <h5>{t('kvkk.section3.requirements.obligation.title')}</h5>
                                <p>{t('kvkk.section3.requirements.obligation.text')}</p>

                                <h5>{t('kvkk.section3.requirements.public.title')}</h5>
                                <p>{t('kvkk.section3.requirements.public.text')}</p>

                                <h5>{t('kvkk.section3.requirements.rights.title')}</h5>
                                <p>{t('kvkk.section3.requirements.rights.text')}</p>

                                <h5>{t('kvkk.section3.requirements.legitimate.title')}</h5>
                                <p>{t('kvkk.section3.requirements.legitimate.text')}</p>

                                {/* Private Data */}
                                <h4>{t('kvkk.section3.privateData.title')}</h4>
                                <p>{t('kvkk.section3.privateData.text')}</p>
                                <ul>
                                    {t('kvkk.section3.privateData.conditions', { returnObjects: true }).map((condition, index) => (
                                        <li key={index}>{condition}</li>
                                    ))}
                                </ul>

                                {/* Enlightening */}
                                <h4>{t('kvkk.section3.enlightening.title')}</h4>
                                <p>{t('kvkk.section3.enlightening.text')}</p>

                                {/* Transfer */}
                                <h4>{t('kvkk.section3.transfer.title')}</h4>
                                <p>{t('kvkk.section3.transfer.text')}</p>
                                <p>{t('kvkk.section3.transfer.conditions')}</p>
                                <ul>
                                    {t('kvkk.section3.transfer.list', { returnObjects: true }).map((item, index) => (
                                        <li key={index}><span>{(index + 1).toString().padStart(2, '0')}-</span>{item}</li>
                                    ))}
                                </ul>

                                {/* Private Data Transfer */}
                                <h4>{t('kvkk.section3.privateDataTransfer.title')}</h4>
                                <p>{t('kvkk.section3.privateDataTransfer.text')}</p>
                                <ul>
                                    {t('kvkk.section3.privateDataTransfer.conditions', { returnObjects: true }).map((condition, index) => (
                                        <li key={index}>{condition}</li>
                                    ))}
                                </ul>

                                {/* Sections 4-8 */}
                                <h3>{t('kvkk.section4.title')}</h3>
                                <p>{t('kvkk.section4.text')}</p>

                                <h3>{t('kvkk.section5.title')}</h3>
                                <p>{t('kvkk.section5.text')}</p>

                                <h3>{t('kvkk.section6.title')}</h3>
                                <h4>{t('kvkk.section6.rights.title')}</h4>
                                <ul>
                                    {t('kvkk.section6.rights.list', { returnObjects: true }).map((right, index) => (
                                        <li key={index}><span>{(index + 1).toString().padStart(2, '0')}-</span>{right}</li>
                                    ))}
                                </ul>

                                <h4>{t('kvkk.section6.rights.title2')}</h4>
                                <p>{t('kvkk.section6.rights.text2')}</p>

                                <h3>{t('kvkk.section7.title')}</h3>
                                <h4>{t('kvkk.section7.activities.title')}</h4>
                                <p>{t('kvkk.section7.activities.text')}</p>

                                <h4>{t('kvkk.section7.monitoring.title')}</h4>
                                <p>{t('kvkk.section7.monitoring.text')}</p>
                                <ul>
                                    {t('kvkk.section7.monitoring.details', { returnObjects: true }).map((detail, index) => (
                                        <li key={index}>{detail}</li>
                                    ))}
                                </ul>

                                <h4>{t('kvkk.section7.tracking.title')}</h4>
                                <p>{t('kvkk.section7.tracking.text')}</p>
                                <p>{t('kvkk.section7.tracking.details')}</p>

                                <h3>{t('kvkk.section8.title')}</h3>
                                <p>{t('kvkk.section8.intro')}</p>
                                {t('kvkk.section8.mainText', { returnObjects: true }).map((text, index) => (
                                    <p key={index}>{text}</p>
                                ))}

                                <h4>{t('kvkk.section8.administrative.title')}</h4>
                                <ul>
                                    {t('kvkk.section8.administrative.measures', { returnObjects: true }).map((measure, index) => (
                                        <li key={index}><span>{(index + 1).toString().padStart(2, '0')}-</span>{measure}</li>
                                    ))}
                                </ul>

                                <h4>{t('kvkk.section8.technical.title')}</h4>
                                <ul>
                                    {t('kvkk.section8.technical.measures', { returnObjects: true }).map((measure, index) => (
                                        <li key={index}><span>{(index + 1).toString().padStart(2, '0')}-</span>{measure}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PrivacyPage
