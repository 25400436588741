import React from 'react';
import SEO from "../../common/SEO";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import { useTranslation } from 'react-i18next';
import getCaseData from "../../data/CaseData";
import { FiCalendar, FiTag } from "react-icons/fi";

const CaseStudies = () => {
    const { t } = useTranslation();
    const cases = getCaseData(t);

    return (
        <>
            <SEO title={t('caseStudy')} />
            <BreadcrumbOne 
                title={t("caseBreadcrumb")}
                rootUrl="/"
                parentUrl="Home"
                currentUrl="Case Studies"
            />

            <div className="main-content">
                <div className="rn-blog-area rn-section-gap">
                    <div className="container">
                        <div className="case-list">
                            {cases.map((item) => (
                                <div key={item.id} className="case-item">
                                    <div className="case-card">
                                        <div className="row align-items-center">
                                            <div className="col-lg-6">
                                                <div className="thumbnail">
                                                    <img 
                                                        src={`${process.env.PUBLIC_URL}/${item.image}`} 
                                                        alt={item.title}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="content">
                                                    <div className="meta">
                                                        <div className="meta-item">
                                                            <FiCalendar className="icon" />
                                                            <span>{item.date}</span>
                                                        </div>
                                                        <div className="meta-item">
                                                            <FiTag className="icon" />
                                                            <span>{item.tags.join(", ")}</span>
                                                        </div>
                                                    </div>

                                                    <h2>{item.title}</h2>
                                                    
                                                    <div className="description">
                                                        {item.body.map((paragraph, index) => (
                                                            <p key={index}>{paragraph}</p>
                                                        ))}
                                                    </div>

                                                    <div className="tags">
                                                        {item.tags.map((tag, index) => (
                                                            <span key={index} className="tag">
                                                                {tag}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .main-content {
                    padding: 120px 0;
                    background: var(--background-color-1);
                }

                .case-list {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                }

                .case-item {
                    background: var(--background-color-2);
                    border-radius: 15px;
                    overflow: hidden;
                    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
                    transition: all 0.3s ease;
                    border: 1px solid var(--color-border);
                }

                .case-item:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 10px 25px rgba(0,0,0,0.3);
                    border-color: var(--color-primary);
                }

                .thumbnail {
                    position: relative;
                    overflow: hidden;
                    border-radius: 10px;
                    margin: 20px;
                }

                .thumbnail img {
                    width: 100%;
                    height: 400px;
                    object-fit: cover;
                    transition: transform 0.3s ease;
                    border-radius: 10px;
                }

                .case-item:hover .thumbnail img {
                    transform: scale(1.05);
                }

                .content {
                    padding: 30px;
                }

                .meta {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;
                }

                .meta-item {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    color: var(--color-body);
                    font-size: 14px;
                }

                .icon {
                    color: var(--color-primary);
                }

                h2 {
                    font-size: 28px;
                    margin-bottom: 20px;
                    color: var(--color-heading);
                    line-height: 1.4;
                }

                .description p {
                    color: var(--color-body);
                    line-height: 1.8;
                    margin-bottom: 15px;
                    opacity: 0.9;
                }

                .tags {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 10px;
                    margin-top: 25px;
                }

                .tag {
                    background: var(--background-color-1);
                    padding: 8px 16px;
                    border-radius: 20px;
                    font-size: 14px;
                    color: var(--color-body);
                    transition: all 0.3s ease;
                    border: 1px solid var(--color-border);
                }

                .tag:hover {
                    background: var(--color-primary);
                    color: var(--color-white);
                    border-color: var(--color-primary);
                }

                @media (max-width: 991px) {
                    .thumbnail {
                        margin: 15px;
                    }

                    .thumbnail img {
                        height: 300px;
                    }

                    .content {
                        padding: 20px;
                    }

                    h2 {
                        font-size: 24px;
                    }
                }

                @media (max-width: 767px) {
                    .thumbnail img {
                        height: 250px;
                    }

                    .meta {
                        flex-direction: column;
                        gap: 10px;
                    }
                }
            `}</style>
        </>
    )
}

export default CaseStudies;
