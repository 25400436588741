const getCaseData = (t) => [
    {
        id: 1,
        title: t('caseStudies.intro.title'),
        image: "images/case/1.webp",
        text: t('caseStudies.intro.team'),
        date: t('common.date.march15'),
        body: [t('caseStudies.intro.description')],
        tags: [
            t('tags.smartHotel'),
            t('tags.ai'),
            t('tags.iot'),
            t('tags.luxury')
        ],
        slug: "smart-luxury"
    },
    {
        id: 2,
        title: t('caseStudies.smart.title'),
        image: "images/case/2.webp",
        text: t('caseStudies.smart.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.smart.intro'),
            t('caseStudies.smart.requirements.title'),
            t('caseStudies.smart.requirements.network'),
            t('caseStudies.smart.requirements.fireTV'),
            t('caseStudies.smart.requirements.location'),
            t('caseStudies.smart.requirements.labeling'),
            t('caseStudies.smart.caseStudy')
        ],
        tags: [
            t('tags.voiceControl'),
            t('tags.smartRoom'),
            t('tags.automation')
        ],
        slug: "smart-room"
    },
    {
        id: 3,
        title: t('caseStudies.case1.title'),
        image: "images/case/7.webp",
        text: t('caseStudies.case1.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.case1.profile'),
            t('caseStudies.case1.solution'),
            t('caseStudies.case1.alexa'),
            t('caseStudies.case1.fireTV'),
            t('caseStudies.case1.outcome')
        ],
        tags: [
            t('tags.voiceControl'),
            t('tags.smartRoom'),
            t('tags.automation')
        ],
        slug: "voice-control"
    },
    {
        id: 4,
        title: t('caseStudies.sustainability.title'),
        image: "images/case/3.webp",
        text: t('caseStudies.sustainability.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.sustainability.energy'),
            t('caseStudies.sustainability.digital'),
            t('caseStudies.sustainability.jacuzzi'),
            t('caseStudies.sustainability.fireTV')
        ],
        tags: [
            t('tags.automation'),
            t('tags.guestExperience'),
            t('tags.personalization')
        ],
        slug: "sustainability"
    },
    {
        id: 5,
        title: t('caseStudies.case2.title'),
        image: "images/case/8.webp",
        text: t('caseStudies.case2.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.case2.solution'),
            t('caseStudies.case2.monitoring'),
            t('caseStudies.case2.usage'),
            t('caseStudies.case2.outcome')
        ],
        tags: [
            t('tags.voiceControl'),
            t('tags.smartRoom'),
            t('tags.automation')
        ],
        slug: "smart-monitoring"
    },
    {
        id: 6,
        title: t('caseStudies.newEra.title'),
        image: "images/case/4.png",
        text: t('caseStudies.newEra.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.newEra.checkin'),
            t('caseStudies.newEra.transfer'),
            t('caseStudies.newEra.vip'),
            t('caseStudies.newEra.alexa')
        ],
        tags: [
            t('tags.greenHotel'),
            t('tags.energyEfficiency'),
            t('tags.sustainableTourism')
        ],
        slug: "new-era"
    },
    {
        id: 7,
        title: t('caseStudies.fireTV.title'),
        image: "images/case/5.webp",
        text: t('caseStudies.fireTV.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.fireTV.content'),
            t('caseStudies.fireTV.gaming'),
            t('caseStudies.fireTV.water'),
            t('caseStudies.fireTV.alexa')
        ],
        tags: [
            t('tags.waterConservation'),
            t('tags.smartManagement'),
            t('tags.sustainability')
        ],
        slug: "fire-tv"
    },
    {
        id: 8,
        title: t('caseStudies.conclusion.title'),
        image: "images/case/6.webp",
        text: t('caseStudies.conclusion.team'),
        date: t('common.date.march15'),
        body: [
            t('caseStudies.conclusion.redefine'),
            t('caseStudies.conclusion.personalized'),
            t('caseStudies.conclusion.digital'),
            t('caseStudies.conclusion.efficiency'),
            t('caseStudies.conclusion.gameChanger')
        ],
        tags: [
            t('tags.digitalExperience'),
            t('tags.smartSystems'),
            t('tags.innovation')
        ],
        slug: "smart-conclusion"
    }
];

export default getCaseData; 