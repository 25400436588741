import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

import Home from "./pages/Home";
import About from "./pages/About";
import For_Property_Owner from "./pages/ForPropertyOwner";
import Kvkk from "./pages/kvkk";
import Contact from "./elements/contact/Contact";
import Error from "./pages/Error";
import CaseStudies from "./pages/CaseStudies";
// Import Css Here 
import './assets/scss/style.scss';


const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Switch>
                    <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={Home} />
                    <Route path={`${process.env.PUBLIC_URL + "/about"}`} exact component={About} />
                    <Route path={`${process.env.PUBLIC_URL + "/propertyowner"}`} exact component={For_Property_Owner} />
                    <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact} />
                    <Route path={`${process.env.PUBLIC_URL + "/error"}`} exact component={Error} />
                    <Route path={`${process.env.PUBLIC_URL + "/kvkk"}`} exact component={Kvkk} />
                    <Route path={`${process.env.PUBLIC_URL + "/case-studies"}`} exact component={CaseStudies} />
           
                </Switch>
            </PageScrollTop>
        </Router>
    )
}


export default App
